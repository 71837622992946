<template>
  <v-navigation-drawer v-model="drawer" :color="drawerColor" app :width="drawerWidth">
    <div class="d-flex flex-column justify-space-between" style="height: 100%">
      <div>
        <v-list :lines="false" nav density="compact">
          <v-list-item class="px-2 py-2">
            <AppLogo />
          </v-list-item>

          <v-divider class="my-2" />

          <template v-if="$vuetify.display.mobile">
            <template v-if="authStore.hasMoreCompanies">
              <v-list-item @click="router.push('/minhas-empresas')">
                <template v-slot:prepend>
                  <AppIcon class="mr-4" name="hugeicons:store-01" size="20" />
                </template>
                <v-list-item-title>{{
                  authStore.company?.name
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </template>

          <v-list-item>
            <AppBtn class="text-center" color="primary" block @click="$router.push('/vendas/novo')">
              <AppIcon class="mr-4" name="hugeicons:shopping-cart-add-01" size="20" />
              Vender
            </AppBtn>
          </v-list-item>
        </v-list>
      </div>

      <div class="scroll">
        <v-list :lines="false" nav density="compact">
          <template v-for="(link, index) in links">
            <template v-if="link.type == 'route'">
              <template v-if="!link.feature || $features.has(link.feature)">
                <template v-if="!link.permission || $acl.can(link.permission)">
                  <v-list-item :to="link.path" :title="link.displayName">
                    <template v-slot:prepend>
                      <AppIcon class="mr-4" :name="link.icon" size="20" />
                    </template>
                  </v-list-item>
                </template>
              </template>
            </template>
          </template>
        </v-list>
      </div>
      <v-divider class="mt-2" />

      <div class="last-list">
        <v-list :lines="false" nav density="compact">
          <v-list-item title="Suporte" @click="handleHelp()">
            <template v-slot:prepend>
              <AppIcon class="mr-4" name="hugeicons:customer-support" size="20" />
            </template>
          </v-list-item>

          <v-list-item @click="$router.push('/configuracoes')">
            <template v-slot:prepend>
              <AppIcon class="mr-4" name="hugeicons:settings-01" size="20" />
            </template>
            <v-list-item-title>Configurações</v-list-item-title>
          </v-list-item>

          <template v-if="$acl.isSystemAdmin()">
            <v-list-item @click="$router.push('/admin')">
              <template v-slot:prepend>
                <AppIcon class="mr-4" name="hugeicons:database-setting" size="20" />
              </template>
              <v-list-item-title>Admin</v-list-item-title>
            </v-list-item>
          </template>

          <v-list-item @click="handleLogout()">
            <template v-slot:prepend>
              <AppIcon class="mr-4" name="hugeicons:logout-03" size="20" />
            </template>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </v-navigation-drawer>
</template>
<script setup>
import { useDisplay, useTheme } from "vuetify";

import links from "@/data/links";

const theme = useTheme();

const display = useDisplay();

const $acl = useAcl();

const $features = useFeatures();

const router = useRouter();

const authStore = useAuthStore();

const userName = computed(() => authStore.userName);

const drawerInitialState = useDisplay().mobile.value ? false : true;

const drawer = ref(drawerInitialState);

const drawerColor = computed(() => {
  return theme.global.current.value.colors.drawer;
});

const drawerWidth = computed(() => {
  if (display.mobile.value == true) {
    return 255;
  }

  return 220;
});

const handleLogout = async () => {
  await authStore.logout();

  router.push("/login");
};

const handleHelp = () => {
  window.open(
    "https://wa.me/553391147649?text=Olá, preciso de ajuda no Apex Comércio."
  );
};

defineExpose({
  drawer,
});
</script>

<style lang="scss">
.scroll {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #e4e4e4 transparent;

  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cabfbf;
  }
}

@media (max-width: 600px) {
  .d-flex.flex-column>.scroll {
    order: 2;
  }

  .d-flex.flex-column>.last-list {
    order: 3;
  }
}
</style>
